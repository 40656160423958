body {
	margin: 0;
	/* padding: 0; */
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	text-decoration: none;
	/* box-sizing: border-box; */
}

.pagination-container {
	display: flex;
	justify-content: center;
	align-items: center;
	/* background: rgba(216, 73, 73, 0.2); */
	width: 100%;
	font-weight: 500;
	font-size: 1.25rem;
	margin-top: 1.5rem;
}

.pagination-container a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	/* padding: 8px 14px; */
	height: 30px;
	width: 30px;
	text-decoration: none;
	transition: background-color 0.2s;
	cursor: pointer;
	color: #7e84a3;
}

.pagination-container a.active {
	color: white;
	border-radius: 4px;
	background-color: #72acff;
	/* border: 1px solid #7cbddb; */
}

.pagination-container a.disabled {
	opacity: 0.2;
}
.pagination-container a:hover:not(.active) {
	background-color: rgb(238, 238, 238);
}
